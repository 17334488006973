:root {
    --gap: 10px;
    --shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.15);

    --slider-height: 16px;
    --slider-width: 85px;
    font-size: 18px;
    --buttonSize: 24px;
    --info-size: 30px;
  }

@media screen and (min-width: 800px) {
  :root {
    --gap: 20px;
    --slider-width: 110px;
    --slider-height: 12px;
    font-size: 18px;
    --buttonSize: 24px;
    // --info-size: 45px;
  }
}

@font-face {
  font-family: Montreal;
  src: url("../PPNeueMontreal-Book.otf") format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Montreal;
  src: url("../PPNeueMontreal-Bold.otf") format('opentype');
  font-weight: 600;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: 200ms;
  transition: opacity 0.5s ease; /* Smooth transition to visible */
  // background-color: black;
  font-family: 'Montreal';
}

body, html {
    height: 100%;
    width: 100vw;
    overflow: hidden;
    touch-action: manipulation; /* Disables pinch-to-zoom and double-tap to zoom */
    // font-family: sans-serif;
}

#stopMotionContainer {
  // background-color: #D0D2D3;
  background:white;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  position: fixed;
  top: 0px;
  left: 0px;
  transition: opacity 0.5s ease; /* Smooth transition to visible */
}

#logoContainer {
  position: fixed;
  height: 17vh;
  width: 22vh;
  top: var(--gap);
  left: 50vw;
  transform: translateX(-50%);
  z-index: 11;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#logo {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain; /* Keeps aspect ratio within container */
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  // pointer-events: none;
}

#info-icon {
  mix-blend-mode: multiply;
  width: var(--info-size);
  // margin-left: -2px;
  transform: translateY(-5px);
}

.info-logo{
  width:100%;
  margin-top:100px;
  position:relative;
  top:0.3em;
  display:none;
  svg{
    width:100%;
    height:auto;
  }
}

#info a{
  display:none;
  font-size: 13px;
  font-weight: 600;
  text-decoration: none;
  color:black;
  line-height: 130%;
  // margin-bottom: .5em;
}

#info.visible .info-logo,
#info.visible a{
  display:block;
}

#info.visible a:hover{
  text-decoration: underline;
}

h3{
  // font-size:1rem;
  font-size:14px;
  // text-transform:uppercase;
  font-weight:600;
}

#info {
  position: fixed;
  top: var(--gap);
  left: var(--gap);
  z-index: 101;
  cursor: pointer;
  font-size: 16px;
  // 
  // border: 1px solid gray;
  // box-shadow: 0px 0px 5px #00000033;
  // backdrop-filter: blur(10px);
  
  // padding: 1.2em 1.2em;
  // overflow: hidden;
  // transition: 500ms;
  // width: 1.6em;
  width: var(--info-size);
  max-height: 1.4em;
}

#info:not(.visible):hover {
  background: white;
  border-radius: 100%;
}

#info:not(.visible) p,
#info:not(.visible) .info-logo{
  pointer-events:none;
}

#info p {
  opacity: 0;
  // display: none;
  // pointer-events: none;
  // max-width: 275px;
  // transition: opacity 200ms;
  user-select: none;
  line-height: 130%;
  width: 230px;
  margin: .5em 0;
  font-size: 13px;
  // font-family: Montreal;
}

#info p.visible {
  // transition: opacity 300ms linear 300ms;
  opacity: 1;
}

#info.visible {
  --padding: 0.5rem;
  max-height: 80vh;
  width: 230px;
  padding: var(--padding);

  margin-top: calc(var(--padding)* -1);
  margin-left: calc(var(--padding)* -1);

  background-color: white;
  box-shadow: var(--shadow);
}

#controls {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: var(--gap);
  right: var(--gap);
  z-index: 90;
  // gap: .7em;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 600px) {
  #controls {
    gap:1em;
  }
}

button {
  border: none;
  width: var(--buttonSize);
  height: var(--buttonSize);
  cursor: pointer;
  box-shadow: var(--shadow);
}

#buttons {
  display: flex;
  align-items: center;
  gap: .4em;
}

#colorMode {
  // height: 1.5em;
  // height:var(--slider-height);
  // width: 1.5em;
  // background-image: url("../colormode.jpg");
  border-radius: 1px;
  background-size: 100% 100%;
  // opacity:0.9;
  backdrop-filter: blur(10px);
  // transform:rotate(-90deg);
  // border-radius: 2px;


  // Talia added this
  // background: linear-gradient(
  //   to right, 
  //   rgba(255, 0, 0, 0.9),     /* Red */
  //   rgba(255, 255, 0, 0.9),   /* Yellow */
  //   rgba(0, 192, 255, 0.9),   /* Light Blue */
  //   rgba(192, 0, 255, 0.9)    /* Purple */
  // );

  background: url('../georges-color-icon.png');
  background-size:cover;
}

#colorMode.image {
  background-image: url("../x.png");
  background-size:cover;
  border: 1px solid black;

}

#shuffle {
  background-image: url("../shuffle.png");
  background-color: transparent;
  background-size:contain;
  position: fixed;
  bottom: var(--gap);
  right: var(--gap);
  width: var(--info-size);
  height: var(--info-size);
  box-shadow: none;
}

// #shuffle {
//   border: 1px solid black;
//   border-radius: 3px;
//   background-image: url("../shuffle2.png");
//   background-color: transparent;
//   background-size:cover;
//   position: fixed;
//   bottom: var(--gap);
//   right: var(--gap);
// }

#scaleSlider {
  box-shadow: var(--shadow);
  font-size: 16px;
  // transform:translateY(50%);
}

/*generated with Input range slider CSS style generator (version 20211225)
https://toughengineer.github.io/demo/slider-styler*/
input[type=range].styled-slider {
  height: 1.5em;
  height:var(--slider-height);
  width:var(--slider-width);
  -webkit-appearance: none;
  border-radius: 100px;
  margin: 0px;
  // width: 200px;
}

/*progress support*/
input[type=range].styled-slider.slider-progress {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 2em + var(--ratio) * (100% - 2em));
}

input[type=range].styled-slider:focus {
  outline: none;
}

/*webkit*/
input[type=range].styled-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  // width: 1.3em;
  // height: 1.3em;
  width:calc(var(--slider-height) - 2px);
  height:calc(var(--slider-height) - 2px);
  border-radius: 1em;
  background: #000000;
  border: none;
  // margin-top: calc(2.2em * 0.5 - 2em * 0.5);
  margin-top:1px;
  cursor:pointer;
}

input[type=range].styled-slider::-webkit-slider-runnable-track {
  // height: 1.5em;
  height:var(--slider-height);
  width:var(--slider-width);
  border: none;
  border-radius: 3.6em;
  background: #FFFFFF;
  box-shadow: none;
  padding-left:1px;
  padding-right:1px;
}

input[type=range].styled-slider.slider-progress::-webkit-slider-runnable-track {
  background: linear-gradient(#FFFFFF,#FFFFFF) 0/var(--sx) 100% no-repeat, #FFFFFF;
}

/*mozilla*/
input[type=range].styled-slider::-moz-range-thumb {
  width: 1.3em;
  height: 1.3em;
  // width:9px;
  // height:9px;
  width:calc(var(--slider-height) - 2px);
  height:calc(var(--slider-height) - 2px);
  border-radius: 1em;
  background: #000000;
  border: none;
  cursor:pointer;
}

input[type=range].styled-slider::-moz-range-track {
  height: 1.5em;
  height:var(--slider-height);
  width:var(--slider-width);
  border: none;
  border-radius: 3.6em;
  background: #FFFFFF;
  box-shadow: none;
}

input[type=range].styled-slider.slider-progress::-moz-range-track {
  background: linear-gradient(#FFFFFF,#FFFFFF) 0/var(--sx) 100% no-repeat, #FFFFFF;
}

/*ms*/
input[type=range].styled-slider::-ms-fill-upper {
  background: transparent;
  border-color: transparent;
}

input[type=range].styled-slider::-ms-fill-lower {
  background: transparent;
  border-color: transparent;
}

input[type=range].styled-slider::-ms-thumb {
  width: 1.5em;
  height: 1.5em;
  height:var(--slider-height);
  width:var(--slider-height);
  border-radius: 1em;
  background: #000000;
  border: none;
  margin-top: 0;
  box-sizing: border-box;
  cursor:pointer;
}

input[type=range].styled-slider::-ms-track {
  height: 1.5em;
  height:var(--slider-height);
  width:var(--slider-width);
  border-radius: 3.6em;
  background: #FFFFFF;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
}

input[type=range].styled-slider.slider-progress::-ms-fill-lower {
  height: 1.5em;
  height:var(--slider-height);
  width:var(--slider-width);
  border-radius: 3.6em 0 0 3.6em;
  margin: -undefined 0 -undefined -undefined;
  background: #FFFFFF;
  border: none;
  border-right-width: 0;
}



.grid-container {
  display: flex;
  gap: var(--gap);
  flex-direction: row;
  // background-color: #ff000022;
  position: relative; 
  height: 100vh;
}

.column {
    gap: var(--gap);
    display: flex;
    flex-direction: column;
    align-items: center;
    // position: fixed;
    // transition: left 300ms; // this breaks horizontal scroll
}

.twoColContainer {
  // gap: var(--gap);
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1;
}

.grid-item {
  overflow: hidden;
  // display: flex;
  // pointer-events: none;
  user-select: none;
  position: relative;
  top: 0px;
  left: 0px;
  transition: 300ms transform, 300ms margin;
  // height: calc(100% + var(--captionHeight))
}

.grid-item img {
  // pointer-events: none;
  transition: 300ms;
  // transition: opacity 500ms, transform 600ms linear, left 300ms;
  opacity: var(--opacity);
  transform: scale(var(--scale));
  position: relative;
  cursor: pointer;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.grid-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay-color);
  pointer-events: none;
  transition: var(--colorTransition);
  // transition: opacity 300ms 200ms, transform 300ms ease;
  // transition-delay: 100ms, 0ms;
  opacity: var(--colorOpacity);
  transform: scale(var(--scale));
}

.column.somethingIsFocused::after {
  content: "";
  position: fixed;
  z-index: 8;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 105vh;
  // background-color: var(--overlay-color);
  background-color: rgba(255,255,255,0.4);
  // background-color: red;
  backdrop-filter: blur(20px);
  pointer-events: none;
  // transition: var(--colorTransition);
  // transition: 300ms;
  // transition: opacity 300ms 200ms, transform 300ms ease;
  // transition-delay: 100ms, 0ms;
  // opacity: var(--colorOpacity);
  // transform: scale(var(--scale));
  border-radius: 0px;
  // opacity: 0.9;
}

.somethingIsFocusedCont {
  z-index: 2;
}

.column::after {
  content: "";
  backdrop-filter: blur(0px);
  background-color: rgba(255,255,255,0);
  transition: 300ms backdrop-filter, 300ms background-color;
  pointer-events: none;
}

.no-transition {
  transition: none !important;
}

.grid-item img, .grid-item::after {
  border-radius: 6px;
}


.draggable {
    // cursor: grab;
}



#tagline {
    position: fixed;
    bottom: calc(var(--gap));
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    margin: 0;
    text-align: center;
    width: 90svw;
}

// .blurBack {
//   display: none;
//   position: fixed;
//   top: 0px;
//   left: 0px;
//   background-color: #FF000066;
//   backdrop-filter: blur(24px);
//   z-index: 100;
// }
// .grid-item.focused ~ .blurBack {
//   display: block;
// }

// .grid-item:not(:has(img.inactive)) .blurBack {
//   // display: block;
//   width: 100vw;
//   height: 100vh;

//   z-index: -2;
// }

// .grid-item:not(:has(img.inactive))::before {
//   position: fixed;
//   top: 0px;
//   left: 0px;
//   width: 100vw;
//   height: 100vh;
//   background-color: #FF0000;
//   // backdrop-filter: blur(24px);
//   z-index: -2;
// }

// .grid-item::before {
//   content: '';
//   display: none;
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: 100vh;
//   background-color: #FF000055;
//   backdrop-filter: blur(24px);
//   z-index: 4;
// }

// .grid-item.focused::before {
//   display: block;
// }


.caption {
  // position: absolute;
  // left: 50%;
  // bottom: -20px;
  // transform: translateX(-50%) translateY(0%);
  // background-color: white;
  color: black;
  // border-radius: 5px;
  transition: 300ms;
  z-index: 9;
  pointer-events: none;
  user-select: none;
  width: 100%;
  box-sizing: border-box;
  // padding: 12px 12px;
  font-size:var(--captionFontSize);
  line-height:1.1;
  // display: none;
  height: 0px;
  transition: 300ms height;
}

// .grid-item.focused .caption {
//   pointer-events: all;
// }

.grid-item.focused .caption .imageLink {
  display: block;
}

.caption .imageLink {
  pointer-events: all !important;
  display: none;
  color: black;
}

@media screen and (min-width: 600px) {
  .caption {
    line-height:1.15;
  }
}

.grid-item.focused {
  overflow: visible;
}

// .grid-item.focused .caption {
//   bottom: -20px;
//   opacity: 1;
//   // left: 0px;
//   padding: 0px;

// }


// .enlarged {
//   position: fixed;
//   // top: 50%;
//   // left: 50%;
//   // transform: translate(-50%, -50%);
//   height: 100vh;
//   width: 100vw;
//   max-width: 90vw; /* 90% of viewport width */
//   max-height: 90vh; /* 90% of viewport height */
//   z-index: 1000;
//   transition: transform 0.3s;
// }




  

@media screen and (min-width: 800px) {
  .grid-item:not(:has(img.inactive)):hover .caption {
    opacity: 1; /* Show caption on hover */
    // opacity: var(--caption)
    bottom: 0px; /* Position caption at bottom of the image */
    // transition-delay: 120ms;
    // transform: translateX(-50%) translateY(100%);
    // height: 100%;
    height: var(--captionHeight)
  }
  .grid-item:not(:has(img.inactive)):hover {
    height: 100%;
  }
}



.grid-item.focused:not(:has(img.inactive)):hover .caption {

}


.grid-item:hover .caption {
    // display: none; /* Show caption on hover */
}

.grid-item:not(:has(img.inactive)).focused .caption {
  opacity: 1;
  // bottom: 0;
  transition: 1000ms opacity;
  // transform: translateX(-50%) translateY(100%);
  padding: 0px;
  font-size: 14px;
  line-height: 1.2;
}

.imageLink:not(.imageLink + .imageLink) {
  margin-top: .6em;
}






.grid-item img {
  width: var(--colWidth);
}


// .grid-item img {
//   width: 220px;
// }


// @media screen and (min-width: 800px) {
//   .grid-item img {
//     width: 300px;
//   }
//   #info.visible{
//     width:205px;
//     p{
//       width:100%;
//     }
//   }
// }

// @media screen and (min-width: 1200px) {
//   .grid-item img {
//     width: 400px;
//   }
// }


// @media screen and (min-width: 1650px) {
//   .grid-item img {
//     width: 500px;
//   }
// }
